import {EventBus} from "../../events/EventBus";
import {DropdownElementClickEvent} from "../../events/Events";

document.querySelectorAll(".js-wl-dropdown").forEach((elements) => {
    elements.querySelectorAll(".js-wl-dropdown-element").forEach((element) => {
        element.addEventListener('click', (event) => {
            EventBus.getInstance().emit(new DropdownElementClickEvent({
                dropdownId: elements.id,
                dataValues: (element as HTMLElement).dataset,
                content: element.textContent || "",
                element: element,
                event: event,
            }));
        });
    });
});